import React from 'react';
import { Helmet } from 'react-helmet';
import { HomeTemplate } from '@we-agile-you/retrospective-app';
import { Layout } from '@we-agile-you/retrospective-app';

export default function ManageAccount() {
  return (
    <Layout>
      <Helmet title="Scrum poker | We Agile You" />
      <HomeTemplate />
    </Layout>
  );
}
